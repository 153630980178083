import React, { useMemo } from 'react';
import { Button } from '@noloco/components';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import { BASE_URI } from '../constants/auth';
import { MediaFile } from '../models/File';
import { ProjectSettings } from '../models/Project';
import { useOpenUrl } from '../utils/hooks/useOpenUrl';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';
import PoweredByNoloco from './PoweredByNoloco';

type Props = {
  logo: any;
  media: MediaFile[];
  projectName: string;
  redirectPath: string | null | undefined;
  settings: ProjectSettings;
};

const SamlLogin = ({
  logo,
  media,
  projectName,
  redirectPath,
  settings,
}: Props) => {
  const { src: logoUrl = getProjectAuthLogo(settings, media) } = logo || {};

  const params = useMemo(() => {
    if (!redirectPath) {
      return '';
    }

    return `?${new URLSearchParams({ redirectPath }).toString()}`;
  }, [redirectPath]);
  const url = `${BASE_URI}/saml/login/${projectName}${params}`;

  const open = useOpenUrl();

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-100">
      <SimpleLayout logoUrl={logoUrl} titleText={getText('auth.login.title')}>
        <div className="mx-2 mt-6 flex flex-col space-y-4 overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
          {url ? (
            <Button
              className="w-full"
              // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
              onClick={() => open(url)}
              variant="primary"
            >
              {getText('auth.login.saml.button')}
            </Button>
          ) : (
            <p className="mx-auto">{getText('errors.genericNoRetry')}</p>
          )}
          <div className="text-center text-sm">
            {getText('auth.login.saml.description')}
          </div>
        </div>
      </SimpleLayout>
      <PoweredByNoloco
        className="mx-auto flex-wrap justify-center text-gray-800"
        projectName={projectName}
        utmSource="noloco_login"
      />
    </div>
  );
};

export default SamlLogin;
