import { useMemo } from 'react';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import builtInDataTypes, {
  DataFieldType,
} from '@noloco/core/src/constants/dataTypes';
import { AND, NOT, OR } from '@noloco/core/src/constants/filters';
import { Relationship } from '@noloco/core/src/constants/relationships';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { fieldNameIsSafeForType } from '@noloco/core/src/utils/dataTypes';
import { getFieldReverseApiName } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType } from '@noloco/core/src/utils/options';
import { DISPLAY_NAME_REGEX } from '../../constants/regex';
import useIsNameUnique from './useIsNameUnique';

const LANG_KEY = 'data.dataTypes.nameValidation';

const useIsDisplayNameValid = (collectionName: string) => {
  return useMemo(
    () => collectionName && DISPLAY_NAME_REGEX.test(collectionName),
    [collectionName],
  );
};

export const useDataTypeNameValidation = (
  collectionName: string,
  dataTypes: DataTypes,
) => {
  const isDisplayNameValid = useIsDisplayNameValid(collectionName);
  const isUnique = useIsNameUnique(collectionName, dataTypes);

  const isValid = isDisplayNameValid && isUnique;

  const validationMessage = useMemo(() => {
    if (isValid || !collectionName) {
      return null;
    }

    if (collectionName.length < 3) {
      return getText(LANG_KEY, 'length');
    }

    if (!isDisplayNameValid) {
      return getText(LANG_KEY, 'characters');
    }

    return getText(LANG_KEY, 'unique');
  }, [collectionName, isDisplayNameValid, isValid]);

  return { isValid, validationMessage };
};

const isNameReserved = (str: any) => str && [OR, AND, NOT].includes(str.trim());

export const useFieldNameValidation = (
  fieldName: string,
  type: DataFieldType | null,
  dataType: DataType,
  dataTypes: DataTypes,
  existingField?: DataField,
) => {
  const camelName = camelCase(fieldName);
  const isDisplayNameValid = useIsDisplayNameValid(fieldName);
  const isReserved = useMemo(() => isNameReserved(camelName), [camelName]);

  const isUnique = useMemo(
    () => dataType.fields.every(({ name }) => name !== camelName),
    [camelName, dataType.fields],
  );

  const isOption = useMemo(() => type !== null && isOptionType(type), [type]);
  const conflictingOptionName = useMemo(
    () =>
      isOption &&
      dataTypes.getByName(`${dataType.name}${upperFirst(camelName)}`),
    [camelName, dataType.name, dataTypes, isOption],
  );

  const isRelationship = useMemo(
    () => type !== null && !builtInDataTypes.includes(type),
    [type],
  );
  const conflictingRelationshipName = useMemo(
    () =>
      isRelationship &&
      dataType.fields.find(
        ({ name }) => name === `${camelName}Id`,
        [camelName, dataType.fields],
      ),
    [camelName, dataType.fields, isRelationship],
  );

  const isTypeSafe = useMemo(
    () => !type || fieldNameIsSafeForType(camelName, type),
    [camelName, type],
  );

  const isValid =
    isDisplayNameValid &&
    !isReserved &&
    isUnique &&
    !conflictingOptionName &&
    !conflictingRelationshipName &&
    isTypeSafe;

  const validationMessage = useMemo(() => {
    if (isValid || !fieldName) {
      return null;
    }

    if (existingField) {
      if (existingField.display === fieldName) {
        // It's unchanged
        return null;
      } else if (
        dataType.fields.some(
          (f) => f.display === fieldName && f.id !== existingField.id,
        )
      ) {
        // It matches another field
        return getText(LANG_KEY, 'unique');
      }
    }

    if (fieldName.length < 3) {
      return getText(LANG_KEY, 'length');
    }

    if (!isDisplayNameValid) {
      return getText(LANG_KEY, 'characters');
    }

    if (isReserved) {
      return getText(LANG_KEY, 'reserved');
    }

    if (!isUnique) {
      return getText(LANG_KEY, 'unique');
    }

    if (conflictingOptionName) {
      return getText(
        {
          conflict: conflictingOptionName.display,
          dataTypeDisplay: dataType.display,
          display: fieldName,
        },
        LANG_KEY,
        'conflictingOption',
      );
    }

    if (conflictingRelationshipName) {
      return getText(
        { conflict: conflictingRelationshipName.display, display: fieldName },
        LANG_KEY,
        'conflictingRelationship',
      );
    }

    if (!isTypeSafe) {
      return getText(LANG_KEY, 'reserved');
    }

    return null;
  }, [
    conflictingOptionName,
    conflictingRelationshipName,
    dataType.display,
    dataType.fields,
    existingField,
    fieldName,
    isDisplayNameValid,
    isReserved,
    isTypeSafe,
    isUnique,
    isValid,
  ]);

  if (existingField) {
    return { isValid: validationMessage === null, validationMessage };
  }

  return { isValid, validationMessage };
};

export const useReverseFieldNameValidation = (
  reverseDisplayName: string,
  relatedType: DataType | null,
  relationship: Relationship,
  fields: DataField[],
) => {
  const camelName = camelCase(reverseDisplayName);
  const reverseName = relatedType
    ? getFieldReverseApiName(
        { reverseName: camelName, relationship } as DataField,
        relatedType,
      )
    : '';
  const isDisplayNameValid = useIsDisplayNameValid(reverseDisplayName);
  const isReserved = useMemo(() => isNameReserved(camelName), [camelName]);
  const isUnique = useMemo(
    () =>
      !!relatedType &&
      relatedType.fields
        .filter((f) => !f.relatedField)
        .every(
          (field: DataField) =>
            field.name !== camelName && field.name !== reverseName,
        ),
    [camelName, relatedType, reverseName],
  );

  const fieldsReverseNames = useMemo(
    () =>
      relatedType
        ? fields
            .filter(
              ({ relationship, type }) =>
                relationship && type === relatedType.name,
            )
            .map(
              (field: DataField & { fieldReverseName?: string }) =>
                field.fieldReverseName ||
                getFieldReverseApiName(field, relatedType),
            )
        : [],
    [fields, relatedType],
  );

  const isUniqueInReverse = useMemo(
    () => !fieldsReverseNames.includes(reverseName),
    [fieldsReverseNames, reverseName],
  );

  const isValid =
    isDisplayNameValid && !isReserved && isUnique && isUniqueInReverse;

  const validationMessage = useMemo(() => {
    if (isValid || !reverseDisplayName) {
      return null;
    }

    if (reverseDisplayName.length < 3) {
      return getText(LANG_KEY, 'length');
    }

    if (!isDisplayNameValid) {
      return getText(LANG_KEY, 'characters');
    }

    if (isReserved) {
      return getText(LANG_KEY, 'reserved');
    }

    if (!isUnique) {
      return getText(LANG_KEY, 'unique');
    }

    if (!isUniqueInReverse) {
      return getText(
        { reverseType: relatedType?.display },
        LANG_KEY,
        'reverseUnique',
      );
    }

    return null;
  }, [
    isValid,
    reverseDisplayName,
    isDisplayNameValid,
    isReserved,
    isUnique,
    isUniqueInReverse,
    relatedType?.display,
  ]);

  return { isValid, validationMessage };
};
