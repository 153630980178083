import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { Loader } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { projectNameSelector } from '@noloco/core/src/selectors/projectSelectors';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import {
  getRelationshipFromMultiOpts,
  isMultiField,
  isReverseMultiField,
} from '@noloco/core/src/utils/relationships';
import { UPDATE_DATA_FIELD } from '../../../queries/project';
import { useUpdateDataField } from '../../../utils/hooks/useUpdateDataTypes';
import NewRelationshipFieldForm from './RelationshipEditor';

type EditRelationshipFieldFormProps = {
  canChangeRelationship: boolean;
  dataType: DataType;
  dataTypes: DataTypes;
  field: DataField;
};

const EditRelationshipFieldForm = ({
  canChangeRelationship,
  dataType,
  dataTypes,
  field,
}: EditRelationshipFieldFormProps) => {
  const projectName = useSelector(projectNameSelector);
  const updateDataField = useUpdateDataField();
  const errorAlert = useGraphQlErrorAlert();
  const [updateField, { loading }] = useMutation(UPDATE_DATA_FIELD);

  const onChangeRelationship = useCallback(
    (isMulti: boolean, isReverseMulti: boolean, reverseDisplay?: string) => {
      const relationship = getRelationshipFromMultiOpts(
        isMulti,
        isReverseMulti,
      );

      updateField({
        variables: {
          projectName,
          id: field.id,
          relationship,
          reverseDisplay,
        },
      })
        .then(({ data }) =>
          updateDataField({
            dataTypeId: dataType.id,
            dataField: data.updateDataField,
          }),
        )
        .catch((e) => {
          errorAlert(getText('data.relationships.error'), e);
        });
    },
    [
      dataType.id,
      errorAlert,
      field.id,
      projectName,
      updateDataField,
      updateField,
    ],
  );

  const isMulti = useMemo(() => isMultiField(field), [field]);
  const isReverseMulti = useMemo(() => isReverseMultiField(field), [field]);

  const onChangeReverseDisplayName = useMemo(
    () =>
      debounce((newReverseDisplayName: string) => {
        if (newReverseDisplayName) {
          onChangeRelationship(isMulti, isReverseMulti, newReverseDisplayName);
        }
      }, 1500),
    [isMulti, isReverseMulti, onChangeRelationship],
  );

  return (
    <div className="relative mb-3 flex w-full px-6">
      <NewRelationshipFieldForm
        dataType={dataType}
        dataTypes={dataTypes}
        displayName={field.display}
        readOnly={!canChangeRelationship || loading}
        isMulti={isMulti}
        isReverseMulti={isReverseMulti}
        relationship={field.relationship!}
        relatedType={dataTypes.getByApiName(field.type)!}
        requiresReverseName={field.type !== FILE}
        reverseName={field.reverseDisplayName!}
        type={field.type}
        surface={LIGHT}
        reverseNameValidationMessage={null}
        setIsMulti={(value: boolean) =>
          onChangeRelationship(value, isReverseMulti)
        }
        setIsReverseMulti={(value: boolean) =>
          onChangeRelationship(isMulti, value)
        }
        setRelationship={() => null}
        setReverseName={onChangeReverseDisplayName}
      />
      {loading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-gray-50 bg-opacity-50">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default EditRelationshipFieldForm;
