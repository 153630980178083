import { useCallback, useMemo } from 'react';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import last from 'lodash/last';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { COUNT } from '../../constants/aggregationTypes';
import { PIVOT_TABLE } from '../../constants/collectionLayouts';
import { OrderByDirection } from '../../constants/orderByDirections';
import {
  PivotTable,
  PivotTableHeader,
  PivotTableValue,
  SUMMARY,
  getRowGrouping,
} from '../../constants/pivotTable';
import { axisFormatter } from '../../elements/Chart';
import { getDataFieldPropAsDep } from '../../elements/ViewCollection';
import { Group, GroupByConfig } from '../../elements/sections/Collection';
import DataTypes, { DataType } from '../../models/DataTypes';
import { Element } from '../../models/Element';
import { RecordEdge } from '../../models/Record';
import { GroupByWithField } from '../../models/View';
import { aggregateNumericalData } from '../aggregationDataTypes';
import { getFieldPathFromPath, getPathForFieldPath } from '../charts';
import { getText } from '../lang';
import useCollectionGroups, {
  getGroupsFromEdgesForLevel,
} from './useCollectionGroups';

const LANG_KEY = 'elements.VIEW';

type PivotTableProps = {
  dataType: DataType;
  dataTypes: DataTypes;
  edges: RecordEdge[];
  element: Element;
  pivotTable: PivotTable;
  updateProperty: UpdatePropertyCallback;
};

const usePivotTable = ({
  dataType,
  dataTypes,
  edges,
  element,
  pivotTable,
  updateProperty,
}: PivotTableProps) => {
  const { aggregation = COUNT, columnGrouping = [], values = [] } = pivotTable;
  const rowGrouping = useMemo(() => getRowGrouping(pivotTable!), [pivotTable]);

  const groupByFields = useMemo(
    () =>
      [rowGrouping, ...columnGrouping]
        .map((group) => {
          const { dependencyField } = getDataFieldPropAsDep(
            group.field,
            dataType,
            dataTypes,
            element.type,
          );

          return { ...group, dataField: dependencyField };
        })
        .filter(
          (group) => group.dataField && !group.collapsed,
        ) as GroupByWithField[],
    [columnGrouping, dataType, element.type, dataTypes, rowGrouping],
  );

  const filteredValues = useMemo<PivotTableValue[]>(
    () =>
      values
        .filter(({ field }) => field)
        .map((value) => ({
          ...value,
          field: {
            ...value.field!,
            path: getFieldPathFromPath(value.field?.path!),
          },
        })),
    [values],
  );

  const filteredColumnGrouping = useMemo(
    () =>
      columnGrouping.filter(
        ({ field, collapsed = false }, index) =>
          field && (index === 0 || !collapsed),
      ),
    [columnGrouping],
  );

  const { groupConfigs, groupsById } = useCollectionGroups({
    dataType,
    edges,
    elementId: element.id,
    groupByFields,
    hideEmptyGroups: true,
    layout: PIVOT_TABLE,
  });

  const groupRowsMap = useMemo(
    () =>
      Object.entries(groupsById)
        .filter(
          ([_, groups]) => groups.length === filteredColumnGrouping.length + 1,
        )
        .reduce(
          (
            result: { [key: string]: (RecordEdge & { index: number })[] },
            [key, groups]: [string, Group[]],
          ) => set([key], get(last(groups), 'rows', []), result),
          {},
        ),
    [filteredColumnGrouping, groupsById],
  );

  const { rowGroups, groupsFromConfig } = useMemo(() => {
    const groups = groupConfigs.reduce(
      (result: Record<string, Group[]>, groupConfig: GroupByConfig) =>
        set(
          getFieldPathFromPath(groupConfig.key),
          getGroupsFromEdgesForLevel(edges, {
            ...groupConfig,
            initialGroups: [],
          }),
          result,
        ),
      {},
    );

    const rowGroupPath = getFieldPathFromPath(rowGrouping.field?.path!);
    let rowGroups: Group[] = get(groups, rowGroupPath, []);
    if (!Array.isArray(rowGroups)) {
      rowGroups = [];
    }

    delete groups[rowGroupPath];
    const groupsFromConfig = groups;

    return { rowGroups, groupsFromConfig };
  }, [edges, groupConfigs, rowGrouping]);

  const columnGroupPath = useMemo(() => {
    const columnParentGroup = first(columnGrouping);

    if (!columnParentGroup || !columnParentGroup.field) {
      return undefined;
    }

    return getPathForFieldPath(columnParentGroup.field, dataType);
  }, [columnGrouping, dataType]);

  const columnGroups = useMemo(() => {
    if (!columnGroupPath) {
      return [];
    }

    return get(groupsFromConfig, columnGroupPath) ?? [];
  }, [columnGroupPath, groupsFromConfig]);

  const formattedValues = useMemo(
    () =>
      values
        .filter(({ field }) => field)
        .map(({ field }) => ({
          id: getFieldPathFromPath(field?.path!),
          label: null,
          valueLabel: field?.display,
        })),
    [values],
  );

  const subGroups = useMemo<PivotTableHeader[]>(() => {
    const [firstSubGroups = [], lastSubGroups = []] = filteredColumnGrouping
      .slice(1)
      .map(({ field }) => groupsFromConfig[getFieldPathFromPath(field?.path!)]);

    if (firstSubGroups.length === 0 && formattedValues.length > 0) {
      return formattedValues.map((formattedValue) => ({
        ...formattedValue,
        valueKey: formattedValue.id,
      }));
    }

    return firstSubGroups.flatMap((firstSubGroup) => {
      if (lastSubGroups.length === 0) {
        return formattedValues.map(({ id, valueLabel }, valueIndex) => ({
          id: firstSubGroup.key,
          labels: [valueIndex === 0 ? firstSubGroup.label : null, null],
          valueKey: id,
          valueLabel,
        }));
      }

      return lastSubGroups.flatMap((lastSubGroup, lastSubGroupIndex) =>
        formattedValues.map(({ id, valueLabel }, valueIndex) => ({
          id: `${firstSubGroup.key}.${lastSubGroup.key}`,
          labels: [
            lastSubGroupIndex === 0 && valueIndex === 0
              ? firstSubGroup.label
              : null,
            valueIndex === 0 ? lastSubGroup.label : null,
          ],
          valueKey: id,
          valueLabel,
        })),
      );
    });
  }, [formattedValues, filteredColumnGrouping, groupsFromConfig]);

  const handleRowGroupingSort = useCallback(
    (sort) => {
      if (rowGrouping.sort !== sort) {
        updateProperty(['pivotTable', 'rowGrouping', 0, 'sort'], sort);
      }
    },
    [rowGrouping, updateProperty],
  );

  const columns = useMemo<PivotTableHeader[]>(
    () => [
      {
        alignRight: false,
        id: rowGrouping.field?.path!,
        isSortActive: true,
        label: rowGrouping.field?.display,
        onSort: (sort: OrderByDirection) => handleRowGroupingSort(sort),
      },
      ...columnGroups.flatMap((parentGroup) =>
        subGroups.map(({ id, labels, valueKey, valueLabel }, index) => ({
          alignRight: true,
          id: `${parentGroup.key}${
            filteredColumnGrouping.slice(1).length > 0 ? `.${id}` : ''
          }`,
          label: index === 0 ? parentGroup.label : null,
          labels,
          valueKey,
          valueLabel,
        })),
      ),
      ...filteredValues.map((value) => ({
        alignRight: true,
        id: `${value.field?.path}.${SUMMARY}`,
        label: value.aggregation
          ? `${value.field?.display} ${value.aggregation}`
          : getText(LANG_KEY, 'pivotTable.summary'),
        valueKey: value.field?.path,
      })),
      ...(filteredValues.length === 0
        ? [
            {
              alignRight: true,
              id: SUMMARY,
              label: getText(LANG_KEY, 'pivotTable.summary'),
            },
          ]
        : []),
    ],
    [
      columnGroups,
      filteredColumnGrouping,
      filteredValues,
      handleRowGroupingSort,
      rowGrouping,
      subGroups,
    ],
  );

  const groupHeaders = useMemo(
    () => columnGrouping.slice(1).filter(({ field }) => field),
    [columnGrouping],
  );

  const valueFormatter = useCallback(
    (field, rawValue) =>
      axisFormatter(
        field,
        dataType,
        dataTypes,
        edges.map(({ node }) => node),
      )(rawValue),
    [dataType, dataTypes, edges],
  );

  const getValue = useCallback(
    (valueKey) =>
      filteredValues.find((value) => value.field?.path === valueKey),
    [filteredValues],
  );

  const getRowData = useCallback(
    (group: Group) => {
      const nonSummaryColumns = columns
        .slice(1)
        .filter(({ id }) => !id.includes(SUMMARY));

      const aggregatedValues: { [key: string]: (number | null)[] } = {};

      const rows = nonSummaryColumns.map(({ id, valueKey }) => {
        const key = `${group.id}.${id}`;
        const value = getValue(valueKey);
        const aggregation = get(value, 'aggregation', COUNT);
        const path = get(value, 'field.path', '');

        const rawValues = (groupRowsMap[`${group.id}.${id}`] ?? []).map(
          ({ node }) => get(node, path, 0),
        ) as number[];

        const aggregate = aggregateNumericalData(rawValues, aggregation);
        const formattedAggregate =
          aggregation === COUNT || aggregate === null
            ? aggregate
            : valueFormatter(value?.field!, aggregate);

        if (valueKey) {
          aggregatedValues[valueKey] = (
            aggregatedValues[valueKey] || []
          ).concat(aggregate);
        }

        return { aggregate, formattedAggregate, key, valueKey };
      });

      const rowSummary = columns
        .filter(({ id }) => id.includes(SUMMARY))
        .map(({ id, valueKey }) => {
          const rawValues = aggregatedValues[valueKey!] || [];
          const value = getValue(valueKey);
          const aggregation = get(value, 'aggregation', COUNT);
          const aggregate = aggregateNumericalData(rawValues, aggregation);
          const formattedAggregate =
            aggregation === COUNT || aggregate === null
              ? aggregate
              : valueFormatter(value?.field!, aggregate);

          return { aggregate, formattedAggregate, key: id, valueKey };
        });

      return [...rows, ...rowSummary];
    },
    [columns, getValue, valueFormatter, groupRowsMap],
  );

  const summaryRow = useMemo(() => {
    const rowData = rowGroups.map((group) => getRowData(group));

    return [
      aggregation,
      ...columns.slice(1).map((_, index) => {
        const rawValues = rowData.map((rowDatum) => ({
          aggregate: rowDatum[index].aggregate,
          valueKey: rowDatum[index].valueKey,
        }));

        if (rawValues.length > 0) {
          const [{ valueKey }] = rawValues;
          const field = getValue(valueKey)?.field;

          const aggregate = aggregateNumericalData(
            rawValues.map((rawValue) => rawValue.aggregate) as (
              | number
              | null
            )[],
            aggregation,
          );
          const formattedAggregate =
            aggregation === COUNT || aggregate === null
              ? aggregate
              : valueFormatter(field, aggregate);

          return formattedAggregate;
        }

        return 0;
      }),
    ];
  }, [columns, rowGroups, aggregation, getValue, getRowData, valueFormatter]);

  return {
    columns,
    getRowData,
    groupHeaders,
    rowGroups,
    summaryRow,
    valueColumns: filteredValues,
  };
};

export default usePivotTable;
