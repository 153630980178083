import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconBookmark, IconSettings, IconTrash } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { Label, Modal } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { AIRTABLE } from '@noloco/core/src/constants/dataSources';
import { TEXT } from '@noloco/core/src/constants/dataTypes';
import {
  VALID_FORMULA_TYPES_TO_CONVERT,
  VALID_TYPES_TO_CONVERT,
} from '@noloco/core/src/constants/fieldConversion';
import { PHONE_NUMBER } from '@noloco/core/src/constants/fieldFormats';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { updateDataType as updateDataTypeState } from '@noloco/core/src/reducers/project';
import { addRelatedFieldsToDataType } from '@noloco/core/src/utils/data';
import { getValueForFieldTypeInput } from '@noloco/core/src/utils/dataTypes';
import { useMutableFieldProperties } from '@noloco/core/src/utils/hooks/useMutableFieldProperties';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType } from '@noloco/core/src/utils/options';
import { UPDATE_DATA_TYPE } from '../../../queries/project';
import FieldVariableNameDetails from '../../dataTable/FieldVariableNameDetails';
import ChangeFieldDisplayInput from './ChangeFieldDisplayInput';
import ChangeFieldTypeInput from './ChangeFieldTypeInput';
import DeleteFieldModal from './DeleteFieldModal';
import EditFieldTypeOptions from './EditFieldTypeOptions';
import EditRelationshipFieldForm from './EditRelationshipFieldForm';
import FieldOptionsInput from './FieldOptionsInput';
import FieldTypeInput from './FieldTypeInput';
import LookupEditor from './LookupEditor';
import RollupEditor from './RollupEditor';

export type Props = {
  canDelete: boolean;
  dataType: DataType;
  dataTypes: DataTypes;
  field: DataField;
  onClose: () => void;
  projectName: string;
  refetchData?: () => Promise<any>;
};

const EditFieldModal = ({
  canDelete,
  dataType,
  dataTypes,
  field,
  onClose,
  projectName,
  refetchData = async () => {},
}: Props) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBulkAddScreen, setShowBulkAddScreen] = useState(false);
  const [updateDataType, { loading: updateLoading }] =
    useMutation(UPDATE_DATA_TYPE);

  const isFormula = !!field?.typeOptions?.formula;

  const getTypeOptions = () =>
    isFormula ? VALID_FORMULA_TYPES_TO_CONVERT : VALID_TYPES_TO_CONVERT;

  const {
    canChangeFieldDisplay,
    canChangeFieldOptions,
    canChangeFieldType,
    canChangeFieldTypeOptions,
    canChangeFieldOptionsColor,
    canDeleteField,
    canSetFieldAsPrimary,
    canChangeRelationship,
    hasFieldTypeOptions,
  } = useMutableFieldProperties(dataType, field);

  const setPrimaryField = useCallback(() => {
    updateDataType({
      variables: {
        projectName,
        id: dataType.id,
        primaryField: field.id,
      },
    }).then(({ data }) => {
      const updatedDataType = data.updateDataType;
      dispatch(
        updateDataTypeState(
          addRelatedFieldsToDataType(updatedDataType, dataTypes),
        ),
      );
    });
  }, [dataType.id, dataTypes, dispatch, field.id, projectName, updateDataType]);

  if (showDeleteModal) {
    return (
      <DeleteFieldModal
        field={field}
        onClose={() => setShowDeleteModal(false)}
        onDeleteSuccess={onClose}
        projectName={projectName}
        dataTypeId={dataType.id}
      />
    );
  }

  return (
    <Modal
      canCancel={false}
      confirmText={getText('data.fields.edit.confirm')}
      icon={<IconSettings size={18} />}
      onClose={onClose}
      onConfirm={onClose}
      title={getText({ field: field.display }, 'data.fields.edit.title')}
    >
      {canChangeFieldDisplay && (
        <ChangeFieldDisplayInput
          className="px-6 pb-4"
          dataType={dataType}
          field={field}
          projectName={projectName}
          surface={LIGHT}
        />
      )}
      {!canChangeFieldType && (
        <div className="w-full px-6">
          <Label className="mb-2" surface={LIGHT}>
            {getText('data.changeTypeInput.label')}
          </Label>
          <FieldTypeInput
            dataTypes={dataTypes}
            filter={() => true}
            objectTypesEnabled={true}
            onChange={undefined}
            readOnly={true}
            value={getValueForFieldTypeInput(field.type, field.typeOptions)}
          />
        </div>
      )}
      {canChangeFieldType && (
        <ChangeFieldTypeInput
          className="px-6 py-4"
          dataType={dataType}
          field={field}
          projectName={projectName}
          refetchData={refetchData}
          surface={LIGHT}
          typeOptions={
            field.source === AIRTABLE ? [TEXT, PHONE_NUMBER] : getTypeOptions()
          }
        />
      )}
      {isOptionType(field.type) && (
        <div className="flex w-full flex-col px-6 py-3">
          <FieldOptionsInput
            dataTypeId={dataType.id}
            projectName={projectName}
            readOnly={!canChangeFieldOptions}
            readOnlyColor={!canChangeFieldOptionsColor}
            field={field}
            showBulkAddScreen={showBulkAddScreen}
            setShowBulkAddScreen={setShowBulkAddScreen}
          />
          {!showBulkAddScreen && canChangeFieldOptions && (
            <button
              className="mt-4 text-left text-sm text-gray-500 hover:underline"
              onClick={() => setShowBulkAddScreen(true)}
            >
              {getText('data.fields.bulkAdd.title')}
            </button>
          )}
        </div>
      )}
      {hasFieldTypeOptions && (
        <EditFieldTypeOptions
          className="w-full px-6 py-3"
          dataType={dataType}
          projectName={projectName}
          refetchData={refetchData}
          readOnly={!canChangeFieldTypeOptions}
          surface={LIGHT}
          field={field}
        />
      )}
      {field.lookup && (
        <div className="flex w-full flex-col px-6 py-3">
          <LookupEditor
            dataType={dataType}
            dataTypes={dataTypes}
            sourceFieldId={field.lookup.sourceField.id}
            valueFieldId={field.lookup.valueField.id}
            readOnly={true}
            surface={LIGHT}
          />
        </div>
      )}
      {field.relationship && (
        <EditRelationshipFieldForm
          canChangeRelationship={canChangeRelationship}
          dataType={dataType}
          dataTypes={dataTypes}
          field={field}
        />
      )}
      {field.rollup && (
        <div className="flex w-full flex-col px-6 py-3">
          <RollupEditor
            aggregation={field.rollup.aggregation}
            dataType={dataType}
            dataTypes={dataTypes}
            rollupField={field.rollup.field}
            rollupRelatedField={field.rollup.relatedField}
            readOnly={true}
            surface={LIGHT}
          />
        </div>
      )}
      <FieldVariableNameDetails field={field} />
      {(canSetFieldAsPrimary || (canDelete && canDeleteField)) && (
        <hr className="mx-4 my-4" />
      )}
      {canSetFieldAsPrimary && (
        <button
          className="mx-2 flex items-center rounded-lg px-3 py-3 text-left text-sm hover:bg-gray-100 disabled:opacity-50"
          disabled={updateLoading}
          onClick={() => setPrimaryField()}
        >
          <IconBookmark size={16} className="mr-4 opacity-75" />
          <span className="w-72">
            {getText('data.fields.primaryField.set')}
          </span>
        </button>
      )}
      {canDelete && canDeleteField && (
        <button
          className="mx-2 flex items-center rounded-lg px-3 py-3 text-left text-sm hover:bg-red-100"
          onClick={() => setShowDeleteModal(true)}
        >
          <IconTrash size={16} className="mr-4 opacity-75" />
          <span className="w-72">{getText('data.fields.delete.button')}</span>
        </button>
      )}
    </Modal>
  );
};

export default EditFieldModal;
