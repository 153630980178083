import { useCallback, useMemo } from 'react';
import { IconCalculator, IconPlus, IconX } from '@tabler/icons-react';
import set from 'lodash/fp/set';
import shortId from 'shortid';
import { Button, Dropdown, SelectInput, Tooltip } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { DataTypeValue } from '@noloco/ui/src/components/canvas/DataTypeInput';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import chartAggregations, {
  COUNT,
  ChartAggregation,
} from '../../../constants/chartAggregations';
import { PIVOT_TABLE } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import { NUMERIC_DATATYPES } from '../../../constants/dataTypes';
import { PivotTable, getRowGrouping } from '../../../constants/pivotTable';
import { DataType } from '../../../models/DataTypes';
import { DepValue, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import StateItem from '../../../models/StateItem';
import { GroupBy } from '../../../models/View';
import { getText } from '../../../utils/lang';
import { getTypeOptionsOfTypeFromParent } from '../../../utils/renderedOptions';
import {
  BuildModeGroupByInput,
  DropdownButton,
} from '../BuildModeGroupByInput';
import BuildModeInput from '../BuildModeInput';
import BuildModeSwitchSection from '../BuildModeSwitchSection';

const LANG_KEY = 'elements.VIEW';

type BuildModePivotTableEditorProps = {
  canMultiGroup: boolean;
  dataType: DataType;
  hasAdvancedGrouping: boolean;
  hideEmptyGroups: boolean;
  pivotTable: PivotTable;
  project: Project;
  stateItem: StateItem;
  updateProperty: UpdatePropertyCallback;
};

const BuildModePivotTableEditor = ({
  canMultiGroup,
  dataType,
  hasAdvancedGrouping,
  hideEmptyGroups,
  pivotTable,
  project,
  stateItem,
  updateProperty,
}: BuildModePivotTableEditorProps) => {
  const {
    aggregation = COUNT,
    columnGrouping = [],
    showTableAggregation = true,
    values = [],
  } = pivotTable;
  const rowGrouping = useMemo(() => getRowGrouping(pivotTable!), [pivotTable]);

  const groupByGroups: GroupBy[] = useMemo(() => {
    if (columnGrouping.length > 0) {
      return columnGrouping;
    }

    return [{ id: 'new' }];
  }, [columnGrouping]);

  const valuesOptionsWithNull = useMemo(
    () =>
      getTypeOptionsOfTypeFromParent(
        project.dataTypes,
        stateItem,
        NUMERIC_DATATYPES,
      ),
    [project.dataTypes, stateItem],
  );

  const aggregationOptions = useMemo(
    () =>
      chartAggregations.map((aggregationOption) => ({
        value: aggregationOption,
        label: getText('elements.CHART.aggregation', aggregationOption),
      })),
    [],
  );

  const onChangePivotTableProps = useCallback(
    (path, value) => updateProperty(['pivotTable', ...path], value),
    [updateProperty],
  );

  const updateGroupsProperty = useCallback(
    (grouping) => (path: ElementPath, value: DepValue) => {
      if (
        grouping === 'rowGrouping' &&
        !Array.isArray(pivotTable.rowGrouping)
      ) {
        // To support pivot tables that have single row grouping field
        const arrayValue = [
          { field: pivotTable.rowGrouping as DepValue, id: shortId.generate() },
        ];

        // Update either the field or the sort order
        const nextValue = set(path, value, arrayValue);

        return updateProperty(['pivotTable', 'rowGrouping'], nextValue);
      }

      updateProperty(['pivotTable', grouping, ...path], value);
    },
    [updateProperty, pivotTable],
  );

  const addValues = useCallback(
    () =>
      onChangePivotTableProps(
        ['values'],
        [
          ...values,
          { aggregation: COUNT, field: null, id: shortId.generate() },
        ],
      ),
    [values, onChangePivotTableProps],
  );

  const removeValue = useCallback(
    (id) =>
      onChangePivotTableProps(
        ['values'],
        values.filter((value) => value.id !== id),
      ),
    [values, onChangePivotTableProps],
  );

  return (
    <>
      <BuildModeGroupByInput
        canMultiGroup={false}
        clearable={false}
        dataType={dataType}
        dataTypes={project.dataTypes}
        groupByGroups={[rowGrouping]}
        groups={[rowGrouping]}
        hasAdvancedGrouping={false}
        hideEmptyGroups={false}
        label={getText(LANG_KEY, 'pivotTable.rowGrouping')}
        layout={PIVOT_TABLE}
        stateItem={stateItem}
        updateProperty={updateGroupsProperty('rowGrouping')}
      />
      <BuildModeGroupByInput
        canMultiGroup={canMultiGroup}
        clearable={false}
        dataType={dataType}
        dataTypes={project.dataTypes}
        groupByGroups={groupByGroups}
        groups={columnGrouping}
        hasAdvancedGrouping={hasAdvancedGrouping}
        hideEmptyGroups={hideEmptyGroups}
        label={getText(LANG_KEY, 'pivotTable.columnGrouping')}
        layout={PIVOT_TABLE}
        stateItem={stateItem}
        updateProperty={updateGroupsProperty('columnGrouping')}
      />
      <BuildModeInput label={getText(LANG_KEY, 'pivotTable.values')}>
        <div className="flex flex-col space-y-2">
          {values.map((value, index) => (
            <div className="flex items-center space-x-2">
              <SelectInput
                Button={DataTypeValue}
                className="w-full text-black"
                contained={true}
                onChange={(field: DepValue) =>
                  onChangePivotTableProps(['values', index, 'field'], field)
                }
                options={valuesOptionsWithNull}
                placeholder={getText(LANG_KEY, 'display.groupBy.placeholder')}
                searchable={true}
                shiftRight={true}
                value={value?.field}
              />
              {value?.field && (
                <Tooltip
                  content={
                    <span className={darkModeColors.text.primary}>
                      {getText(LANG_KEY, 'pivotTable.summaryType')}
                    </span>
                  }
                  placement="top"
                  showArrow={false}
                  surface={DARK}
                >
                  <Dropdown
                    Button={DropdownButton}
                    onChange={(aggregation: ChartAggregation) =>
                      onChangePivotTableProps(
                        ['values', index, 'aggregation'],
                        aggregation,
                      )
                    }
                    options={aggregationOptions}
                    size={MD}
                    value={value?.aggregation ?? COUNT}
                    stopPropagation={true}
                  >
                    <IconCalculator size={16} />
                  </Dropdown>
                </Tooltip>
              )}
              {values.length > 1 && (
                <Button
                  className="bg-slate-800 hover:bg-slate-900"
                  onClick={() => removeValue(value.id)}
                >
                  <IconX size={16} />
                </Button>
              )}
            </div>
          ))}
          {values.length <= 2 && (
            <Button
              className="flex w-fit items-center space-x-2 bg-slate-700 hover:bg-slate-900"
              onClick={addValues}
              size={SM}
            >
              <IconPlus className="opacity-75" size={16} />
              <span>{getText(LANG_KEY, 'pivotTable.addValue')}</span>
            </Button>
          )}
        </div>
      </BuildModeInput>
      <BuildModeSwitchSection
        label={getText(LANG_KEY, 'pivotTable.showTableSummary')}
        onChange={(value) =>
          onChangePivotTableProps(['showTableAggregation'], value)
        }
        value={showTableAggregation}
      >
        <SelectInput
          className="m-2"
          onChange={(aggregation: ChartAggregation) =>
            onChangePivotTableProps(['aggregation'], aggregation)
          }
          options={aggregationOptions}
          placeholder={getText(LANG_KEY, 'pivotTable.tableSummaryType')}
          value={aggregation}
        />
      </BuildModeSwitchSection>
    </>
  );
};

export default BuildModePivotTableEditor;
